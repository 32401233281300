let googleTranslateData = `
<div class="lgBar">
  <div class="lgBarInner">
    <div id="googleTranslateElement"></div>
    <script>
      function googleTranslateElementInit() {
        new google.translate.TranslateElement({
          pageLanguage: 'ja',
          includedLanguages: 'fr,en,zh-CN,zh-TW,ko',
          layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
        },'googleTranslateElement');
    }
    </script>
    <script src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script>
  </div>
</div>`;


export const common_pc = () => {
  $(".googleTranslateWrapperPc").append($(googleTranslateData));

  $('.selectCheck').click(function() {
    $(this).siblings().removeClass('clickOn');
    $(this).toggleClass('clickOn');

    if ($(this).hasClass('headerNav__language')) {
      $('.googleTranslateWrapperPc').toggleClass('fadeIn');
      $('.searchBar--pc').removeClass('fadeIn');
    } else if($(this).hasClass('headerNav__search')) {
      $('.searchBar--pc').toggleClass('fadeIn');
      $('.googleTranslateWrapperPc').removeClass('fadeIn');
    }
  })

}

export const common_sp = () => {
  $(".googleTranslateWrapperSp").append($(googleTranslateData));

  $('.headerNavSpSub__language a').click(function() {
    $('.googleTranslateWrapperSpOutter').toggleClass('fadeIn');
    return false;
  })

  $('.headerNavSp__menu a').click(function() {
    $('.subNavWrapper').toggleClass('fadeIn');
    return false;
  })

  $('.subMenuSpClose').click(function() {
    $('.subNavWrapper').removeClass('fadeIn');
  })

  $('.searchTextInputSp').focus(function(){
    $('.searchBar--sp').addClass('focusOn');
  });

  $('.searcCancel').click(function() {
    $('.searchTextInputSp').val("");
    $('.searchBar--sp').removeClass('focusOn');
   })
}