export const common = () => {

  $('.newClubContents .tabNav').click(function() {
    $('.newClubContents .isActive').removeClass('isActive');
    $(this).addClass('isActive');

    $('.newClubContents .isShow').removeClass('isShow');

    //自分の親は何番目なのかを調べる
    let index = $(this).parent().index();
    $('.newClubContents .tabNavContents').eq(index).addClass('isShow');

    return false;
  });


  $('.showYourStatusContents .tabNav').click(function() {
    $('.showYourStatusContents .isActive').removeClass('isActive');
    $(this).addClass('isActive');

    $('.showYourStatusContents .isShow').removeClass('isShow');

    //自分の親は何番目なのかを調べる
    let index = $(this).parent().index();
    $('.showYourStatusContents .tabNavContents').eq(index).addClass('isShow');

    return false;
  });


  $('.saveThosePointsContents .tabNav').click(function() {
    return false;
  });


  $('#vipSection .tabNav').click(function() {
    $('#vipSection .isActive').removeClass('isActive');
    $(this).addClass('isActive');

    $('#vipSection .isShow').removeClass('isShow');

    //自分の親は何番目なのかを調べる
    let index = $(this).parent().index();
    $('#vipSection .tabNavContents').eq(index).addClass('isShow');

    return false;
  });


  $('#myPageSection .tabNav').click(function() {
    $('#myPageSection .isActive').removeClass('isActive');
    $(this).addClass('isActive');

    $('#myPageSection .isShow').removeClass('isShow');

    //自分の親は何番目なのかを調べる
    let index = $(this).parent().index();
    $('#myPageSection .tabNavContents').eq(index).addClass('isShow');
    $('#myPageSection .tabNavContentsImg').eq(index).addClass('isShow');

    return false;
  });


  $('.question').click(function() {
    $(this).next().stop(true,true).slideToggle(500, 'easeOutCubic');
    $(this).toggleClass('isShow');
  })
}


export const pc = () => {
  let top_Offset = $('html').offset().top + 100;
  let new_Offset = $('#newClubSection').offset().top -220;
  let status_Offset = $('#showYourStatusSection').offset().top -220;
  let points_Offset = $('#saveThosePointsSection').offset().top -220;
  let vip_Offset = $('#vipSection').offset().top -220;
  let myPage_Offset = $('#myPageSection').offset().top -220;
  let qa_Offset = $('#qaSection').offset().top + 30;

  $('.pcHeaderNav--top').click(function() {
    $('body, html').animate({ scrollTop: top_Offset }, 1000);
    return false;
  });

  $('.pcHeaderNav--new').click(function() {
    $('body, html').animate({ scrollTop: new_Offset }, 1000);
    return false;
  });

  $('.pcHeaderNav--status').click(function() {
    $('body, html').animate({ scrollTop: status_Offset }, 1000);
    return false;
  });

  $('.pcHeaderNav--points').click(function() {
    $('body, html').animate({ scrollTop: points_Offset }, 1000);
    return false;
  });

  $('.pcHeaderNav--vip').click(function() {
    $('body, html').animate({ scrollTop: vip_Offset }, 1000);
    return false;
  });

  $('.pcHeaderNav--myPage').click(function() {
    $('body, html').animate({ scrollTop: myPage_Offset }, 1000);
    return false;
  });

  $('.pcHeaderNav--qa').click(function() {
    $('body, html').animate({ scrollTop: qa_Offset }, 1000);
    return false;
  });

}

export const sp = () => {
  $('.menuSwitch').click(function() {
    $('.ukainnHeaderNavMenuSP').slideToggle(500, 'easeOutCubic');
    $(this).toggleClass('clicked');
  })

  let myPageSlider = $('.myPageSlider');
  myPageSlider.slick ({
    slidesToShow: 1,
    dots: false,
    prevArrow: '<div class=prev></div>',
    nextArrow: '<div class=next></div>',
    //centerPadding: '20%'
  });


}