export const common = () => {

  let midtown = {
    data_storeTel: "03-5413-7236",
    data_storeName1: "六本木",
    data_storeName2: "uka 東京ミッドタウン 六本木",
    data_telTime: "9:30〜21:00"
  }
  let hiroo = {
    data_storeTel: "03-3449-0421",
    data_storeName1: "広尾",
    data_storeName2: "uka 広尾店",
    data_telTime: "9:30〜20:00<br>日曜 9:30〜19:00"
  }
  let kitte = {
    data_storeTel: "03-3217-2011",
    data_storeName1: "丸の内",
    data_storeName2: "uka 丸の内KITTE店",
    data_telTime: "平日・土 10:30～21:00<br>日・祝 10:30～20:00<br>※祝前日は受付時間が異なります。"
  }
  let yebis = {
    data_storeTel: "03-6450-4306",
    data_storeName1: "恵比寿",
    data_storeName2: "uka 恵比寿三越店",
    data_telTime: "9:30～20:00"
  }
  let ginza6 = {
    data_storeTel: "03-6263-9981",
    data_storeName1: "銀座",
    data_storeName2: "uka GINZA SIX",
    data_telTime: "10:30〜20:30"
  }
  let ba = {
    data_storeTel: "03-6457-4042",
    data_storeName1: "新宿",
    data_storeName2: "BeautyApothecary spa by uka",
    data_telTime: "10:00〜20:00"
  }
  let haneda = {
    data_storeTel: "03-6459-9080",
    data_storeName1: "羽田空港",
    data_storeName2: "uka イセタン羽田ストア レディス",
    data_telTime: "9:00〜18:00"
  }


  $('.emptySeatInfo').click(function() {
    if($(this).hasClass('midtown')) {
      dataInput(midtown);
    } else if($(this).hasClass('hiroo')) {
      dataInput(hiroo);
    } else if($(this).hasClass('kitte')) {
      dataInput(kitte);
    } else if($(this).hasClass('yebis')) {
      dataInput(yebis);
    } else if($(this).hasClass('ginza6')) {
      dataInput(ginza6);
    } else if($(this).hasClass('ba')) {
      dataInput(ba);
    } else if($(this).hasClass('haneda')) {
      dataInput(haneda);
    }
  })

  function dataInput(e) {
    $('.reservationWrapper .areaB .storeName1').text(e.data_storeName1);
    $('.reservationWrapper .areaB .storeName2').text(e.data_storeName2);
    $('.reservationWrapper .areaB .storeTel_1').attr("href", "tel:"+e.data_storeTel)
    $('.reservationWrapper .areaB .storeTel_2').text(e.data_storeTel);
    $('.reservationWrapper .areaB .telTime').html(e.data_telTime);
  }




/*
let app = new Vue ({
  el:'#vueApp',
  data: {
    message: 'Hello Vue!'
  }
})
*/

}


export const top_pc = () => {
  let $slider = $('.topicsSlider');
  $slider.slick ({
    slidesToShow:1,
    dots:true,
    centerPadding: '20%'
  });

  let newsSlider =  $('.newsSlider');
  newsSlider.slick ({
    infinite: false,
    slidesToShow:5,
    variableWidth: true,
    dots:true,
    //respondTo:'slider',
    slidesToScroll: 5,
  });
/*
  $slider.slick ({
    infinite: true,
    adaptiveHeight: false,
    centerMode: true,
    respondTo: 'slider',
    variableWidth: true,//これで横幅の指定ができて、高さも固定になった。
    centerPadding: '20%'//左右のチラ見せ具合を調整 https://peacepopo.net/blog-entry-238.html
    prevArrow: '<div cWlass="slider-arrow slider-arrow-left"></div>',
    nextArrow: '<div class="slider-arrow slider-arrow-right"></div>',
  });
*/
/*
  let $slider = $('.commonSlider');
  $slider.slick ({
    infinite: false,
    adaptiveHeight: false,
    centerMode: false,
    variableWidth: true,//これで横幅の指定ができて、高さも固定になった。
    swipeToSlide: true,
    prevArrow: '<div class="slider-arrow slider-arrow-left"></div>',
    nextArrow: '<div class="slider-arrow slider-arrow-right"></div>',
  });
*/

  $('.titleLink--01').hover(function() {
    //$(this).next().toggleClass('fadeIn');
    //$(this).toggleClass('fadeIn');
    $(this).find('.subMenuWrapperInner').toggleClass('fadeIn');
  })

}

export const top_sp = () => {
  let $slider = $('.topicsSlider');
  $slider.slick ({
    slidesToShow:1,
    dots:true,
    centerPadding: '20%'
  });

  let newsSlider =  $('.newsSlider');
  newsSlider.slick ({
    infinite: false,
    slidesToShow:3,
    variableWidth: true,
    dots:true,
    slidesToScroll: 3,
  });

  $('.titleLink--01').click(function() {
    $(this).find('.subMenuWrapperInner').slideToggle(700, 'easeOutCubic');
  })


/*
  let $slider = $('.topicsSlider');
  $slider.slick ({
    infinite: true,
    adaptiveHeight: false,
    centerMode: true,
    respondTo: 'slider',
    variableWidth: true,//これで横幅の指定ができて、高さも固定になった。
    centerPadding: '20%'//左右のチラ見せ具合を調整 https://peacepopo.net/blog-entry-238.html
    //prevArrow: '<div class="slider-arrow slider-arrow-left"></div>',
    //nextArrow: '<div class="slider-arrow slider-arrow-right"></div>',
  });
*/


/*
  let $sliderType_01 = $('.sliderType-01');
  $sliderType_01.slick ({
    infinite: true,
    adaptiveHeight: false,
    centerMode: true,
    respondTo: 'slider',
    variableWidth: true,//これで横幅の指定ができて、高さも固定になった。
    centerPadding: '20%'//左右のチラ見せ具合を調整 https://peacepopo.net/blog-entry-238.html
  });


  let $commonSlider = $('.commonSlider');
  $commonSlider.slick ({
    infinite: true,
    adaptiveHeight: false,
    centerMode: true,
    respondTo: 'slider',
    variableWidth: true,//これで横幅の指定ができて、高さも固定になった。
    centerPadding: '20%'//左右のチラ見せ具合を調整 https://peacepopo.net/blog-entry-238.html
  });

  let storeEleHeight = $(".storeWrapper .sliderType-01 li").eq(0).height();
  console.log(storeEleHeight);
  //+$(".storeWrapper .sliderType-01 li").height(storeEleHeight);
*/
}