
import * as common from './common';
import * as top from './top';
import * as ukainn from './ukainn';
/*

import * as about from './about';
import * as servise from './servise';
import * as whatIsSoap from './whatIsSoap';
import * as system from './system';
import * as faq from './faq';
*/
window.onload = function() {
//読み込み終わり


  $(".cartjs_cart_in input").val("今すぐ購入");

  const mediaQueryList = matchMedia('(min-width:768px)');
  mediaQueryList.addListener(onMediaQueryChange);



  function onMediaQueryChange(mediaQueryList) {
    if(mediaQueryList.matches == true){
      //console.log("PC")
      common.common_pc();

      if($('body').hasClass('top')) {
        top.top_pc();
      }

      //ukainnの固定ページの場合
      if($('body').hasClass('page-id-1406')) {
        ukainn.common();
        ukainn.pc()
      }

    } else {
      //console.log("SP")
      common.common_sp();
      if($('body').hasClass('top')) {
        top.top_sp();
      }

      //ukainnの固定ページの場合
      if($('body').hasClass('page-id-1406')) {
        ukainn.common();
        ukainn.sp()
      }

    }
  }
  onMediaQueryChange(mediaQueryList);
}

